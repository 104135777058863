/*CSS RESET*/
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --toastify-toast-width: 30%;
}

/* .nav-item {
  width: 30%;
} */

.dPrint {
  display: none;
}

@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    background-color: transparent !important;
    color: black;
    font-size: 0.7rem;
  }

  #section-not-to-print,
  #section-not-to-print * {
    display: none;
  }

  .dPrint {
    display: block;
  }

  .d-PrintFlex {
    display: flex !important;
    flex-direction: row !important;
  }

  .d-print-m-t {
    margin-top: 100px !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.list:hover {
  background-color: #25ba3c;
}

.list {
  background-color: #e6ece7;
}

.AllList {
  background-color: #e6ece7;
}

.App {
  text-align: center;
}

:root {
  --custom-info: #51a7c2;
  --custom-danger: #b72a35;
  --custom-warning: #f0ae0c;
  --custom-success: #1dbc36;
  --custom-bg-info: rgba(139, 221, 241, 0.1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

Select {
  cursor: pointer !important;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* Date range picker css */
.date-range {
  position: absolute;
  top: 49px;
  z-index: 2;
}

.add-analysis .date-range {
  position: absolute;
  top: 49px;
  left: -110px;
  z-index: 2;
}

.modalHeader .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--font-color);
  background: var(--btn-close-bg)
    url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e')
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.checbox-hide *[type='checkbox'] {
  opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

.gm-style {
  background: var(--bg-color) !important;
}

.insight-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  row-gap: 1rem !important;
  column-gap: 0.4rem !important;
}

.advisory-model {
  width: 1500px !important;
}
